const BaseUrl ="https://ecr-cpt-qa.basf.com/api";
export const environment = {
  dashboardURL: "https://app.powerbi.com/links/X3mwpkE7Eo?ctid=ecaa386b-c8df-4ce0-ad01-740cbdb5ba55/",
  production: false,
  env_name: "qa",
  importExcel: `${BaseUrl}/Contacts/CreateContracts`,
  getAllContracts: `${BaseUrl}/Contacts/GetAllContractDetails`,
  importSalesExcel: `${BaseUrl}/SalesData/CreateSalesData`,
  getSalesData: `${BaseUrl}/SalesData/GetAllSalesData`,
  getContractDetail: `${BaseUrl}/Contacts/GetContractById`,
  getMasterTable: `${BaseUrl}/Master/GetMasterData`,
  saveContracts: `${BaseUrl}/Contacts/saveContract`,
  downloadCommentfile: `${BaseUrl}/Contacts/Download/File`,
  sendEmailwdNotify: `${BaseUrl}/Contacts/WdNotify`,
  getTrueUpReport: `${BaseUrl}/Reports/TrueUpReport`,
  getMissingPurchaseReport: `${BaseUrl}/Reports/MissingPurchaseReport`,
  emailContractPDFData: `${BaseUrl}/Contacts/emailContractPDF`,
  getBoardReport: `${BaseUrl}/Reports/BoardReport`,
  getGridHeader:`${BaseUrl}/Reports/GridHeader`,
  getSAMLURL: `${BaseUrl}/Saml/GetSamlURL`,
  decryptUserDetails: `${BaseUrl}/Users/decryptUserdetails?decryptUser=`,
  getUserInfo: `${BaseUrl}/Users/GetUserDetails?BASFUserID=`,
  getUserRole: `${BaseUrl}/Users/getAllRoles`,
  getUserDetails:`${BaseUrl}/Users/ActiveDirectory/Email`,
  getUsers:`${BaseUrl}/Users/getallusers`,
  saveUser:`${BaseUrl}/Users/updateUser`,
  deleteUser:`${BaseUrl}/Users/deleteUser`,
  ContractPerfReport: `${BaseUrl}/Reports/ContractPerfReport`,
  getMasterReport: `${BaseUrl}/Reports/MasterReport`,
  getExpiringContract:  `${BaseUrl}/Reports/ExpiringContract`,
  getAuditlogs: `${BaseUrl}/Contacts/GetAuditLogById`,
  getIdByContractNumber: `${BaseUrl}/Contacts/GetIdByContractNumber`,
  getSFAccountDetails: `${BaseUrl}/Contacts/GetSFAccountDetails`,
  getMissingContractStatusReasonReport: `${BaseUrl}/Reports/MissingContractStatusReason`,
  getMPRReport: `${BaseUrl}/Reports/MPRReport`,
  getZeroPurchaseReport: `${BaseUrl}/Reports/ZeroPurchaseReport`,
  deleteContact: `${BaseUrl}/Contacts/DeleteContact`,
  getWdEmails: `${BaseUrl}/Contacts/GetWdEmails`,
  getMasterKey: `${BaseUrl}/Master/GetMasterKey`,
  getMasterValueByKey: `${BaseUrl}/Master/getMasterValueByKey`,
  updateMasterData: `${BaseUrl}/Master/updateMasterData`,
  addMasterData: `${BaseUrl}/Master/addMasterData`,
  getWdEmailList: `${BaseUrl}/Master/GetWDEmailList`,
  addWdEmail: `${BaseUrl}/Master/AddWDEmail`,
  deleteWDEmail: `${BaseUrl}/Master/deleteWDEmail`,
  getWdEmailMapping: `${BaseUrl}/Master/GetWdEmailMapping`,
  addWdEmailMapping: `${BaseUrl}/Master/AddWdEmailMapping`,
  deleteWDEmailMapping: `${BaseUrl}/Master/DeleteWDEmailMapping`,
  deleteLookUp: `${BaseUrl}/Master/deleteLookUp`,
};
